html,
body,
#root {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.active-link {
  color: blue;
}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container > img {
  width: unset !important;
  height: unset !important;
}

img {
  pointer-events: none;
}
